$redGerent: #EF3E24;

figcaption{
  text-align: left;
}

blockquote:after,
blockquote:before{
  content: '”';
  color: $redGerent;
  font-family: Georgia;
  font-size: 36px;
  font-weight: 400;
  line-height: 36px;
  display: inline-block;
}
blockquote:before{
  position: absolute;
  content: '“';
  left: 0;
}

blockquote:after{ margin-left: 12px; }

.bg-white a{ color: $redGerent; }

.more-like-this-item:first-child{ margin-left: 0; }
.more-like-this-item:last-child{ margin-right: 0; }

.collapse-expand.collapsed .read-less{
  display: none;
}

.collapse-expand.collapsed .read-more{
  display: block;
}

.collapse-expand.expanded .read-less{
  display: block;
}

.collapse-expand.expanded .read-more{
  display: none;
}

.podcasts-list-item:last-child{
  border: none;
}

.more-like-this-image-wrapper{
  &:after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(51, 51, 51, 0.25);
  }
}

.filter-link{
  &.active::after{
    content: 'x';
    display: inline-block;
    font-weight: normal;
    position: absolute;
    right: 0;
  }
}

.success-history-link{
  transition: all 0.2s ease-in-out;
  background-color: rgba(0,0,0, .5);
  &:hover{
    background-color: $redGerent;
  }
}
